/**
 * Timetastic
 * Features > Calendar Connections page
 */
import React, { useEffect } from "react"
import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import FeaturesCrossLinks from "src/components/featuresCrossLinks"
import FeaturesQuotes from "src/components/featuresQuotes"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrialAlt from "src/components/freeTrialAlt"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SignupButton from "src/components/signupButton"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/features.scss"

// SEO
const title = "Outlook & Google calendar leave management"
const description =
  "Connect Google calendar or Outlook to Timetastic and see your time off work requests feed directly into your calendar."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} useTitleTemplate={false} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-features-main">
          <section className="c-section u-text-centre">
            <div className="u-inner u-inner--l">
              <div className="c-features-intro__description">
                <h1 className="h h1 c-features-index__title">
                  Only the{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">features you need, </span>
                  nothing more, nothing less
                </h1>
              </div>
            </div>
            <div className="u-inner">
              <p className="c-features-intro__summary">
                <strong>Before Timetastic:</strong> Booking a day off is a real
                pain. Spreadsheets, shared calendars, phone calls and paper
                forms. <strong>With Timetastic:</strong> Relax. Just fire up the
                app, choose the days and book - everything is tracked and
                reconciled. You’ll love it.
              </p>
            </div>
          </section>

          <section className="c-section c-features-index--gradient-bottom">
            <div className="c-features-index">
              <div className="u-inner u-inner--l">
                <div className="c-features-index-grid__item">
                  <div className="c-features-grid__description c-features-index-grid__description">
                    <h2 className="c-features-grid__title">
                      Get notifications in Slack
                    </h2>
                    <p>
                      If you love Slack, then dealing with absence requests in
                      your direct messages is a big win.
                    </p>
                    <Link
                      className="c-features-cross-link__link u-em-link u-em-link--light"
                      to="/features/slack-tools/"
                    >
                      <span>Slack tools</span>
                      {/* <svg
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z"
                        fill="currentColor"
                      />
                    </svg> */}
                    </Link>
                  </div>
                  <div className="c-features-grid__media">
                    <img
                      loading="eager"
                      alt="Outlook Calendar"
                      className="c-features-grid__image"
                      src="/images/features/slack-tools/absence-requests@2x.png"
                      width={570}
                      height={315}
                    />
                  </div>
                </div>
                <div className="c-features-index-grid__item">
                  <div className="c-features-grid__description c-features-index-grid__description">
                    <h2 className="c-features-grid__title">
                      Sync with your calendars
                    </h2>
                    <p>
                      Connect Timetastic up to your regular calendar and all
                      your absence bookings will automatically feed in.
                    </p>
                    <Link
                      className="c-features-cross-link__link u-em-link u-em-link--light"
                      to="/features/calendar-connections/"
                    >
                      <span>Calendar connections</span>
                    </Link>
                  </div>
                  <div className="c-features-grid__media">
                    <img
                      loading="eager"
                      alt="Outlook Calendar"
                      className="c-features-grid__image"
                      src="/images/features/calendar-connections/outlook-calendar@2x.png"
                      width={570}
                      height={321}
                    />
                  </div>
                </div>
                <div className="c-features-index-grid__item">
                  <div className="c-features-grid__description c-features-index-grid__description">
                    <h2 className="c-features-grid__title">
                      You’ll always know what’s going on
                    </h2>
                    <p>
                      You get daily and weekly email updates. Every morning,
                      straight into your inbox you’ll get an email telling you
                      who’s off that day.
                    </p>
                    <Link
                      className="c-features-cross-link__link u-em-link u-em-link--light"
                      to="/features/email-reports/"
                    >
                      <span>Email reports</span>
                    </Link>
                  </div>
                  <div className="c-features-grid__media">
                    <img
                      loading="lazy"
                      alt="Outlook Calendar"
                      className="c-features-grid__image"
                      src="/images/features/email-reports/todays-absences@2x.png"
                      width={570}
                      height={305}
                    />
                  </div>
                </div>
                <div className="c-features-index-grid__item">
                  <div className="c-features-grid__description c-features-index-grid__description">
                    <h2 className="c-features-grid__title">
                      Book any type of absence - holidays, sickness, maternity
                    </h2>
                    <p>
                      Timetastic gives you custom leave types. So you can book
                      any reason to be off work that fits with your company
                      policy.
                    </p>
                    <Link
                      className="c-features-cross-link__link u-em-link u-em-link--light"
                      to="/features/leave-types/"
                    >
                      <span>Leave types</span>
                    </Link>
                  </div>
                  <div className="c-features-grid__media">
                    <ul class="u-tag-list">
                      <li class="">Annual Leave</li>
                      <li class="">Sickness</li>
                      <li class="">Maternity/Paternity</li>
                      <li class="">Sabbatical</li>
                      <li class="">Doctors Appointment</li>
                      <li class="">Seasonal Shutdown</li>
                      <li class="">Compassionate</li>
                      <li class="">Away Day</li>
                      <li class="">Training</li>
                    </ul>
                  </div>
                </div>
                <div className="c-features-index-grid__item">
                  <div className="c-features-grid__description c-features-index-grid__description">
                    <h2 className="c-features-grid__title">
                      Upgrade security and insights
                    </h2>
                    <p>
                      Say hello to Timetastic Pro - built for growing businesses
                      like yours.
                    </p>
                    <Link
                      className="c-features-cross-link__link u-em-link u-em-link--light"
                      to="/features/timetastic-pro/"
                    >
                      <span>Timetastic Pro</span>
                    </Link>
                  </div>
                  <div className="c-features-grid__media">
                    <img
                      loading="lazy"
                      alt="Absence at a glance"
                      className="c-features-grid__image"
                      src="/images/features/timetastic-pro/absence-insights@2x.png"
                      width={570}
                      height={288}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <FreeTrialAlt
            body="If you’re still wondering about something in Timetastic, or need to see how something works. Just shout, we’re here to help."
            buttonCopy="Start free trial today"
            title="If you’re ready, give it a try now"
            trackCtaLabel="FeaturesFooterSignUp"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
